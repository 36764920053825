<template>
  <b-img
    :width="width"
    :height="height"
    :src="isWhite ? appLogoImageWhite : appLogoImage"
  />
</template>

<script>
import { BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BImg,
  },
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 25,
    },
    isWhite: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
  },
  setup() {
    const { appLogoImage, appLogoImageWhite } = $themeConfig.app;
    return {
      appLogoImage,
      appLogoImageWhite,
    };
  },
};
</script>
